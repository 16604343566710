import React, {Component} from 'react';
import axios from 'axios';

export default class Contact extends Component {
    constructor() {
        super();

        this.state = {
            openSuccess: false,
            openError: false,
            disableAction: false,
            data: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
            }
        };
    }

    submit = (event) => {
        event.preventDefault();
        this.setState({disableAction: true});

        const data = Object.assign(
            {
                site: 'Shopping Cia Termal',
                to: 'contato@shoppingciatermal.com.br',
            },
            this.state.data
        );

        axios
            .post('https://us-central1-weggo-e399a.cloudfunctions.net/sendContactEmail' , {data})
            .then((res) => {
                if (res.data.message !== 'Email sent') {
                    this.setState({ openError: true, disableAction: false });
                    setTimeout(() => this.setState({ openError: false }), 5000);

                } else {
                    this.setState({
                        openSuccess: true,
                        disableAction: false,
                        data: {
                            name: '',
                            email: '',
                            phone: '',
                            subject: '',
                            message: '',
                        }
                    });
                    setTimeout(() => this.setState({ openSuccess: false }), 5000);
                }

            })
            .catch(() => {
                this.setState({ openError: true, disableAction: false });
                setTimeout(() => this.setState({ openError: false }), 5000)
            })
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState(Object.assign(this.state.data, {[nam]: val}));
    }

    render() {
        return <div>
            <section id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                            <div className="main-title text-left sm-text-center contact-para-setting">
                                <h2>Entre em contato com a Cia Termal</h2>
                                <p>Sua opinião é importante para nós.
                                    Temos uma equipe pronta para ouvir você.</p>
                            </div>

                            <div className="col-md-12 p-0">
                                <div className="col-lg-6 col-md-6 col-sm-6 p-0 float-left">
                                    <div className="contact-info sm-text-center">
                                        <div className="icon-box">
                                            <i className="ti ti-mobile color-grey" />
                                            <p className="color-grey">
                                                <a className="color-grey"
                                                   href={"callto:(64)3452-1541"}>
                                                    (64) 3452-1541
                                                </a>
                                            </p>
                                        </div>

                                        <div className="icon-box">
                                            <i className="ti ti-mobile color-grey" />
                                            <p className="color-grey">
                                                <a className="color-grey"
                                                   href={"callto:(64)99218-0659"}>
                                                    (64) 99218-0659
                                                </a>
                                            </p>
                                        </div>

                                        <div className="icon-box">
                                            <i className="ti ti-email color-grey" />
                                            <p>
                                                <a href="mailto:contato@shoppingciatermal.com.br"
                                                  className="color-grey">contato@shoppingciatermal.com.br</a>
                                            </p>
                                        </div>

                                        <div className="icon-box">
                                            <i className="ti ti-location-pin color-grey" />
                                            <p className="color-grey">
                                                Av. Brasil Qd. 07 Lt. 05 B Bairro Esplanada – Rio Quente - Go, 75.695-000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                            <div className="text-left sm-text-center w-100 mt-sm-40px">
                                <h3 className="font-30 color-black font-weight-500 mt-3 mb-2rem">Envie-nos uma messagem</h3>
                                <p className="mb-2rem">Falar com você é sempre uma satisfação</p>
                            </div>

                            <div className="company-contact-form">
                                <form className="contact-form-outer" onSubmit={this.submit}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="contact-form-textfield pb-4">
                                                <input type="text"
                                                       value={this.state.data.name}
                                                       placeholder="Nome"
                                                       className="form-control"
                                                       required
                                                       id="name"
                                                       name="name"
                                                       onChange={this.myChangeHandler}/>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="contact-form-textfield pb-4">
                                                <input type="email"
                                                       value={this.state.data.email} placeholder="Email"
                                                       className="form-control"
                                                       required id="email"
                                                       name="email"
                                                       onChange={this.myChangeHandler}/>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="contact-form-textfield pb-4">
                                                <input type="tel"
                                                       value={this.state.data.phone} placeholder="Telefone"
                                                       className="form-control"
                                                       id="phone"
                                                       name="phone"
                                                       onChange={this.myChangeHandler}/>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="contact-form-textfield pb-4">
                                                <input type="text"
                                                       value={this.state.data.subject} placeholder="Assunto"
                                                       className="form-control"
                                                       required id="subject"
                                                       name="subject"
                                                       onChange={this.myChangeHandler}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="contact-form-textfield pb-4">
                                                <textarea value={this.state.data.message} placeholder="Mensagem"
                                                          className="form-control message"
                                                          id="message"
                                                          name="message"
                                                          onChange={this.myChangeHandler}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 pt-xs-25px">
                                            <button type="submit"
                                                    disabled={this.state.disableAction}
                                                    className="btn-setting radius-0 btn-hvr-setting-main btn-yellow btn-hvr text-uppercase"
                                                    id="submit_btn">
                                                Enviar Mensagem
                                                <span className="btn-hvr-setting btn-hvr-pink">
                                                    <span className="btn-hvr-setting-inner">
                                                        {!this.state.disableAction ?
                                                            (
                                                                <div>
                                                                    <span className="btn-hvr-effect"/>
                                                                    <span className="btn-hvr-effect"/>
                                                                    <span className="btn-hvr-effect"/>
                                                                    <span className="btn-hvr-effect"/>
                                                                </div>
                                                            ) : ''}
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.openSuccess && (
                    <div className="success-contact">E-mail enviado com sucesso</div>
                )}

                {this.state.openError && (
                    <div className="error-contact">Erro ao enviar o e-mail, tente novamente</div>
                )}

            </section>
        </div>
    }
}
