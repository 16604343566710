import React, {Component} from "react";
import HeaderMenu from "../modules/headerMenu";
import FullMenu from "../modules/fullMenu";
import MainSlider from "../modules/mainSlider";
import TextBoxSection from "../modules/textBoxSection";
import MakeYourselfSection from "../modules/makeYourselfSection";
import Contact from "../modules/contact";
import Map from "../modules/map";
import Footer from "../modules/footer";
import GallerySection from "../modules/gallerySection";
import PartnersSection from "../modules/partnersSection";
import Whats from "../modules/whats";

export default class Home extends Component {
    render() {
        return <div>
            <HeaderMenu/>
            <FullMenu />
            <MainSlider />
            <TextBoxSection />
            <MakeYourselfSection />

            <GallerySection />

            <PartnersSection />

            <Contact />
            <Map />
            <Footer />

            <Whats />
        </div>
    }
}
