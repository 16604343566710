import React, {Component} from 'react';
import whats from '../style/images/whats.png'

export default class Whats extends Component {
    render() {
        return <div>
            <a onClick={() => window.gtag_report_conversion('https://wa.me/5564992180659')}
               target="_blank"
               rel="noopener noreferrer">

                <img src={whats}
                     className="whats-button"
                     style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 100000 }}
                     alt="Whats App" />

            </a>
        </div>
    }
}
