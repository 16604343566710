import React, {Component} from "react";

export default class Map extends Component {
    render() {
        return <div className="container-fluid">
            <div className="row">
                <div id="google-map" className="bg-light-gray map"/>
            </div>
        </div>
    }
}
