import React, {Component} from "react";
import logo from '../style/images/cia/logo_vertical.png';

export default class FullMenu extends Component {
    render() {
        return <section className="overlay-menu fullscreen center-block">
            <h2 className="d-none">heading</h2>
            <div className="quarter-circle">
                <div className="menu_bars2 active">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <span className="overlay-effect"/>

            <div className="centered">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <img src={logo} className="logo-full mb-5 mb-xs-2rem" alt=""/>
                        </div>

                        <div className="col-md-12 text-left mt-5 mb-5">
                            <ul className="side-nav-content heading_space">
                                <li className="full-menu-hover">
                                    <div className="nav-item">
                                        <span className="d-inline-block">01.</span>
                                        <a href="/#home" className="closeOnClick nav-link">home</a>
                                    </div>
                                </li>

                                <li className="full-menu-hover">
                                    <div className="nav-item">
                                        <span className="d-inline-block">02.</span>
                                        <a href="/monte-seu-biquini" className="nav-link closeOnClick">Monte Seu Biquini</a>
                                    </div>
                                </li>

                                <li className="full-menu-hover">
                                    <div className="nav-item">
                                        <span className="d-inline-block">03.</span>
                                        <a href="/#work" className="nav-link closeOnClick">Galeria Principal</a>
                                    </div>
                                </li>

                                <li className="full-menu-hover">
                                    <div className="nav-item">
                                        <span className="d-inline-block">04.</span>
                                        <a href="/#partners" className="nav-link closeOnClick">Marcas Parceiras</a>
                                    </div>
                                </li>

                                <li className="full-menu-hover">
                                    <div className="nav-item">
                                        <span className="d-inline-block">05.</span>
                                        <a href="/#contact" className="nav-link closeOnClick">Contato</a>
                                    </div>
                                </li>
                            </ul>

                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="side-nav-social">
                                <a href="https://www.facebook.com/ciatermal" target="_blank" rel="noopener noreferrer" className="facebook-bg-hvr">
                                    <i className="ti ti-facebook" aria-hidden="true"/>
                                </a>

                                <a style={{border: '1px solid'}} href="https://www.instagram.com/cia.termal/" target="_blank" rel="noopener noreferrer" className="instagram-bg-hvr">
                                    <i className="ti ti-instagram" aria-hidden="true"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}
