import React, {Component} from "react";
import HeaderMenu from "../modules/headerMenu";
import FullMenu from "../modules/fullMenu";
import MakeYourselfChooseSection from "../modules/makeYourselfChooseSection";
import Contact from "../modules/contact";
import Map from "../modules/map";
import Footer from "../modules/footer";
import Whats from "../modules/whats";

export default class MakeYourself extends Component {
    render() {
        return <div>
            <HeaderMenu/>
            <FullMenu/>
            <MakeYourselfChooseSection/>

            <Contact />
            <Map />
            <Footer />

            <Whats />
        </div>
    }
}
