import React, {Component} from "react";
import logo from '../style/images/cia/logo_horizontal.png';

export default class HeaderMenu extends Component {
    render() {
        return <div>
            <header className="header-with-topbar">
                {/*Social Media*/}
                <div className="top-header-area d-xs-none bg-pink">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-md-8 col-am-12 d-xs-none" aria-hidden="true">
                                <a href={"callto:(64)3452-1541"} className="font-14 color-white" title="Call">
                                    <i className="ti ti-mobile mr-2"/>
                                    (64) 3452-1541
                                </a>
                                <div className="bg-light-gray d-inline-block d-xs-none position-relative mr-2 ml-2"/>
                                <a href={"callto:(64)99218-0659"} className="font-14 color-white" title="Call">
                                    <i className="ti ti-mobile mr-2"/>
                                    (64) 99218-0659
                                </a>
                                <div className="bg-light-gray d-inline-block d-xs-none position-relative mr-2 ml-2"/>
                                <a href="mailto:contato@shoppingciatermal.com.br" className="font-14 color-white xs-width-100" title="Mail">
                                    <i className="ti ti-envelope mr-2"/>contato@shoppingciatermal.com.br
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-12 text-right w-265">
                                <div className="bar-social-icon">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                    <a href="https://www.facebook.com/ciatermal" target="_blank" rel="noopener noreferrer" className="facebook-bg-hvr"><i className="ti ti-facebook" aria-hidden="true"/></a>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                    <a href="https://instagram.com/cia.termal/" target="_blank" rel="noopener noreferrer" className="instagram-bg-hvr"><i className="ti ti-linkedin" aria-hidden="true"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Menu link*/}
                <nav className="navbar navbar-top-default navbar-expand-lg static-nav nav-style full-nav bg-white2 no-animation">
                    <div className="container">
                        <a href="/#home">
                            <img src={logo} alt="logo" title="Logo" className="logo-default"/>
                            <img src={logo} alt="logo" title="Logo" className="logo-scrolled"/>
                        </a>

                        <div className="collapse navbar-collapse d-none d-lg-block">
                            <ul className="nav navbar-nav ml-auto menu__list">
                                <li className="nav-item menu__item">
                                    <a href="/#home" className="nav-link menu__link">Home</a>
                                </li>
                                <li className="nav-item menu__item">
                                    <a href="/monte-seu-biquini" className="nav-link menu__link">Monte Seu Biquini</a>
                                </li>
                                <li className="nav-item menu__item">
                                    <a href="/#work" className="nav-link menu__link">Galeria Principal</a>
                                </li>
                                <li className="nav-item menu__item">
                                    <a href="/#partners" className="nav-link menu__link">Marcas Parceiras</a>
                                </li>
                                <li className="nav-item menu__item">
                                    <a href="/#contact" className="nav-link menu__link">Contato</a>
                                </li>
                            </ul>
                        </div>

                        <a className="menu_bars d-inline-block menu-bars-setting">
                            <span/>
                            <span/>
                            <span/>
                        </a>
                    </div>
                </nav>
            </header>
        </div>
    }
}
