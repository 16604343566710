import React, {Component} from "react";
import img from '../style/images/cia/(banner)-Monte-Seu-Biquini-(site).jpg';

export default class MakeYourselfSection extends Component {
    render() {
        return <div>
            <section className="stats bg-light-gray half-section p-0" id="makeYourself">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12 bg-yellow p-0  col-video">
                            <div className="video-content-setting center-block text-left text-xs-center equalheight">
                                <h2 className="color-white font-weight-500 mb-2rem mb-xxs-3">
                                    Monte seu Biquini <br />Porque você é única
                                </h2>
                            </div>

                            <a href="/monte-seu-biquini"
                               className="video-play-button position-absolute">
                                <i className="ti ti-control-play" />
                            </a>
                        </div>

                        <div className="col-md-6 col-sm-12 p-0 equalheight col-video video-bg">
                            <div className="image split-blog-scale">
                                <img alt="stats" src={img} className="video-img-setting" style={{display: 'block', objectFit: 'cover'}} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}
