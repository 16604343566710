import React, {Component} from "react";

export default class Loader extends Component {
    render() {
        return <div>
            {!this.props.show ?
                ('') :
                (<div className="loader" id="loader-fade">
                    <div className="loader-container center-block">
                        <div className="grid-row">
                            <div className="col center-block">
                                <ul className="loading reversed">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    }
}
