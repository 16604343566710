const imagesTop = [
    {name: '01_CORTININHA_SEM_BOJO_LISO_01', src: '01_CORTININHA_SEM_BOJO_LISO_01.jpg'},
    {name: '02_BOJO_MEIA_TAÇA_LISO_01', src: '02_BOJO_MEIA_TACA_LISO_01.jpg'},
    {name: '02_BOJO_MEIA_TAÇA_LISO_03', src: '02_BOJO_MEIA_TACA_LISO_03.jpg'},
    {name: '02_BOJO_MEIA_TAÇA_LISO_05', src: '02_BOJO_MEIA_TACA_LISO_05.jpg'},
    {name: '02_BOJO_MEIA_TAÇA_LISO_07', src: '02_BOJO_MEIA_TACA_LISO_07.jpg'},
    {name: '02_BOJO_MEIA_TAÇA_LISO_09', src: '02_BOJO_MEIA_TACA_LISO_09.jpg'},
    {name: '03_BOJO_MEIA_TAÇA_LISO_01', src: '03_BOJO_MEIA_TACA_LISO_01.jpg'},
    {name: '03_BOJO_MEIA_TAÇA_LISO_03', src: '03_BOJO_MEIA_TACA_LISO_03.jpg'},
    {name: '03_BOJO_MEIA_TAÇA_LISO_05', src: '03_BOJO_MEIA_TACA_LISO_05.jpg'},
    {name: '03_BOJO_MEIA_TAÇA_LISO_07', src: '03_BOJO_MEIA_TACA_LISO_07.jpg'},
    {name: '03_BOJO_MEIA_TAÇA_LISO_09', src: '03_BOJO_MEIA_TACA_LISO_09.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_01', src: '04_BOJO_MEIA_TACA_ESTAMPADO_01.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_03', src: '04_BOJO_MEIA_TACA_ESTAMPADO_03.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_05', src: '04_BOJO_MEIA_TACA_ESTAMPADO_05.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_07', src: '04_BOJO_MEIA_TACA_ESTAMPADO_07.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_09', src: '04_BOJO_MEIA_TACA_ESTAMPADO_09.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_11', src: '04_BOJO_MEIA_TACA_ESTAMPADO_11.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_13', src: '04_BOJO_MEIA_TACA_ESTAMPADO_13.jpg'},
    {name: '04_BOJO_MEIA_TAÇA_ESTAMPADO_15', src: '04_BOJO_MEIA_TACA_ESTAMPADO_15.jpg'},
    {name: '05_BOJO_TRIANGULAR_ALÇA_REGULÁVEL_LISO_01', src: '05_BOJO_TRIANGULAR_ALCA_REGULAVEL_LISO_01.jpg'},
    {name: '05_BOJO_TRIANGULAR_ALÇA_REGULÁVEL_LISO_03', src: '05_BOJO_TRIANGULAR_ALCA_REGULAVEL_LISO_03.jpg'},
    {name: '05_BOJO_TRIANGULAR_ALÇA_REGULÁVEL_LISO_05', src: '05_BOJO_TRIANGULAR_ALCA_REGULAVEL_LISO_05.jpg'},
    {name: '05_BOJO_TRIANGULAR_ALÇA_REGULÁVEL_LISO_07', src: '05_BOJO_TRIANGULAR_ALCA_REGULAVEL_LISO_07.jpg'},
    {name: '05_BOJO_TRIANGULAR_ALÇA_REGULÁVEL_LISO_09', src: '05_BOJO_TRIANGULAR_ALCA_REGULAVEL_LISO_09.jpg'},
    {name: '06_CORTININHA_SEM_BOJO_LISO_01', src: '06_CORTININHA_SEM_BOJO_LISO_01.jpg'},
    {name: '06_CORTININHA_SEM_BOJO_LISO_03', src: '06_CORTININHA_SEM_BOJO_LISO_03.jpg'},
    {name: '06_CORTININHA_SEM_BOJO_LISO_05', src: '06_CORTININHA_SEM_BOJO_LISO_05.jpg'},
    {name: '06_CORTININHA_SEM_BOJO_LISO_07', src: '06_CORTININHA_SEM_BOJO_LISO_07.jpg'},
    {name: '06_CORTININHA_SEM_BOJO_LISO_09', src: '06_CORTININHA_SEM_BOJO_LISO_09.jpg'},
    {name: '07_CORTININHA_SEM_BOJO_LISO_01', src: '07_CORTININHA_SEM_BOJO_LISO_01.jpg'},
    {name: '07_CORTININHA_SEM_BOJO_LISO_03', src: '07_CORTININHA_SEM_BOJO_LISO_03.jpg'},
    {name: '07_CORTININHA_SEM_BOJO_LISO_05', src: '07_CORTININHA_SEM_BOJO_LISO_05.jpg'},
    {name: '08_CORTININHA_SEM_BOJO_ESTAMPADO_01', src: '08_CORTININHA_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '08_CORTININHA_SEM_BOJO_ESTAMPADO_03', src: '08_CORTININHA_SEM_BOJO_ESTAMPADO_03.jpg'},
    {name: '09_CORTINÃO_SEM_BOJO_ESTAMPADO_01', src: '09_CORTINAO_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '09_CORTINÃO_SEM_BOJO_ESTAMPADO_03', src: '09_CORTINAO_SEM_BOJO_ESTAMPADO_03.jpg'},
    {name: '09_CORTINÃO_SEM_BOJO_ESTAMPADO_05', src: '09_CORTINAO_SEM_BOJO_ESTAMPADO_05.jpg'},
    {name: '10_CORTINÃO_SEM_BOJO_LISO_01', src: '10_CORTINAO_SEM_BOJO_LISO_01.jpg'},
    {name: '10_CORTINÃO_SEM_BOJO_LISO_03', src: '10_CORTINAO_SEM_BOJO_LISO_03.jpg'},
    {name: '17_BOJO_MEIA_TAÇA_LISO_01', src: '17_BOJO_MEIA_TACA_LISO_01.jpg'},
    {name: '17_BOJO_MEIA_TAÇA_LISO_03', src: '17_BOJO_MEIA_TACA_LISO_03.jpg'},
    {name: '18_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01', src: '18_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01.jpg'},
    {name: '18_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_03', src: '18_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_03.jpg'},
    {name: '19_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01', src: '19_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01.jpg'},
    {name: '20_CORTININHA_SEM_BOJO_ESTAMPADO_01', src: '20_CORTININHA_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '21_BOJO_MEIA_TAÇA_ESTAMPADO_01', src: '21_BOJO_MEIA_TACA_ESTAMPADO_01.jpg'},
    {name: '22_BOJO_MEIA_TAÇA_ESTAMPADO_01', src: '22_BOJO_MEIA_TACA_ESTAMPADO_01.jpg'},
    {name: '23_CORTININHA_SEM_BOJO_ESTAMPADO_01', src: '23_CORTININHA_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '24_CORTINÃO_SEM_BOJO_ESTAMPADO_01', src: '24_CORTINAO_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '24_CORTINÃO_SEM_BOJO_ESTAMPADO_03', src: '24_CORTINAO_SEM_BOJO_ESTAMPADO_03.jpg'},
    {name: '25_CORTINÃO_SEM_BOJO_LISO_01', src: '25_CORTINAO_SEM_BOJO_LISO_01.jpg'},
    {name: '26_BOJO_TRIANGULAR_ALÇA_REGULAVEL_ESTAMPADO_01', src: '26_BOJO_TRIANGULAR_ALCA_REGULAVEL_ESTAMPADO_01.jpg'},
    {name: '27_CORTININHA_SEM_BOJO_ESTAMPADO_01', src: '27_CORTININHA_SEM_BOJO_ESTAMPADO_01.jpg'},
    {name: '28_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01', src: '28_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01.jpg'},
    {name: '29_CORTININHA_SEM_BOJO_BICOLOR_01', src: '29_CORTININHA_SEM_BOJO_BICOLOR_01.jpg'},
    {name: '30_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01', src: '30_TOP_FRENTE_UNICA_COM_BOJO_ESTAMPADO_01.jpg'},
    {name: '31_TOP_FRENTE_UNICA_COM_BOJO_LISO_01', src: '31_TOP_FRENTE_UNICA_COM_BOJO_LISO_01.jpg'},
    {name: '32_CORTININHA_DE_BABADO_COM_BOJO_LISA_01', src: '32_CORTININHA_DE_BABADO_COM_BOJO_LISA_01.jpg'},
    {name: '33_CORTINÃO_BOJO_TRIANGULAR', src: '33_CORTINAO_BOJO_TRIANGULAR.jpg'}
];

const imagesDown = [
    {name: '01_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg', src: '01_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg'},
    {name: '02_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg', src: '02_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg'},
    {name: '02_CALCINHA_LATERAL_DRAPEADA_LISA_04.jpg', src: '02_CALCINHA_LATERAL_DRAPEADA_LISA_04.jpg'},
    {name: '02_CALCINHA_LATERAL_DRAPEADA_LISA_06.jpg', src: '02_CALCINHA_LATERAL_DRAPEADA_LISA_06.jpg'},
    {name: '02_CALCINHA_LATERAL_DRAPEADA_LISA_08.jpg', src: '02_CALCINHA_LATERAL_DRAPEADA_LISA_08.jpg'},
    {name: '02_CALCINHA_LATERAL_DRAPEADA_LISA_10.jpg', src: '02_CALCINHA_LATERAL_DRAPEADA_LISA_10.jpg'},
    {name: '03_CALCINHA_ASA_DELTA_02.jpg', src: '03_CALCINHA_ASA_DELTA_02.jpg'},
    {name: '03_CALCINHA_ASA_DELTA_04.jpg', src: '03_CALCINHA_ASA_DELTA_04.jpg'},
    {name: '03_CALCINHA_ASA_DELTA_06.jpg', src: '03_CALCINHA_ASA_DELTA_06.jpg'},
    {name: '03_CALCINHA_ASA_DELTA_08.jpg', src: '03_CALCINHA_ASA_DELTA_08.jpg'},
    {name: '03_CALCINHA_ASA_DELTA_10.jpg', src: '03_CALCINHA_ASA_DELTA_10.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_06.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_06.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_08.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_08.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_10.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_10.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_12.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_12.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_14.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_14.jpg'},
    {name: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_16.jpg', src: '04__CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_16.jpg'},
    {name: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_02.jpg', src: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_02.jpg'},
    {name: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_04.jpg', src: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_04.jpg'},
    {name: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_06.jpg', src: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_06.jpg'},
    {name: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_08.jpg', src: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_08.jpg'},
    {name: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_10.jpg', src: '05_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_LISA_10.jpg'},
    {name: '06_CALCINHA_FIO_DUPLO_LISA_02.jpg', src: '06_CALCINHA_FIO_DUPLO_LISA_02.jpg'},
    {name: '06_CALCINHA_FIO_DUPLO_LISA_04.jpg', src: '06_CALCINHA_FIO_DUPLO_LISA_04.jpg'},
    {name: '06_CALCINHA_FIO_DUPLO_LISA_06.jpg', src: '06_CALCINHA_FIO_DUPLO_LISA_06.jpg'},
    {name: '06_CALCINHA_FIO_DUPLO_LISA_08.jpg', src: '06_CALCINHA_FIO_DUPLO_LISA_08.jpg'},
    {name: '06_CALCINHA_FIO_DUPLO_LISA_10.jpg', src: '06_CALCINHA_FIO_DUPLO_LISA_10.jpg'},
    {name: '07_CALCINHA_DE_AMARRAR_LISA_02.jpg', src: '07_CALCINHA_DE_AMARRAR_LISA_02.jpg'},
    {name: '07_CALCINHA_DE_AMARRAR_LISA_04.jpg', src: '07_CALCINHA_DE_AMARRAR_LISA_04.jpg'},
    {name: '07_CALCINHA_DE_AMARRAR_LISA_06.jpg', src: '07_CALCINHA_DE_AMARRAR_LISA_06.jpg'},
    {name: '08_CALCINHA_ASA_DELTA_ESTAMPADA_02.jpg', src: '08_CALCINHA_ASA_DELTA_ESTAMPADA_02.jpg'},
    {name: '08_CALCINHA_ASA_DELTA_ESTAMPADA_04.jpg', src: '08_CALCINHA_ASA_DELTA_ESTAMPADA_04.jpg'},
    {name: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg', src: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg'},
    {name: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_04.jpg', src: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_04.jpg'},
    {name: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_06.jpg', src: '09_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_06.jpg'},
    {name: '10_CALCINHA_DRAPEADA_LARGA_LISA_02.jpg', src: '10_CALCINHA_DRAPEADA_LARGA_LISA_02.jpg'},
    {name: '10_CALCINHA_DRAPEADA_LARGA_LISA_04.jpg', src: '10_CALCINHA_DRAPEADA_LARGA_LISA_04.jpg'},
    {name: '17_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg', src: '17_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg'},
    {name: '17_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg', src: '17_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg'},
    {name: '18_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg', src: '18_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg'},
    {name: '18_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg', src: '18_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_04.jpg'},
    {name: '19__CALCINHA_DRAPEADA_LISA_02.jpg', src: '19__CALCINHA_DRAPEADA_LISA_02.jpg'},
    {name: '20_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg', src: '20_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg'},
    {name: '21_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg', src: '21_CALCINHA_DRAPEADA_LARGA_ESTAMPADA_02.jpg'},
    {name: '22_CALCINHA_TRADICIONAL_FINA_02.jpg', src: '22_CALCINHA_TRADICIONAL_FINA_02.jpg'},
    {name: '23_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg', src: '23_CALCINHA_LATERAL_DRAPEADA_ESTAMPADA_02.jpg'},
    {name: '24_CALCINHA_FIO_DUPLO_ESTAMPADO_02.jpg', src: '24_CALCINHA_FIO_DUPLO_ESTAMPADO_02.jpg'},
    {name: '24_CALCINHA_FIO_DUPLO_ESTAMPADO_04.jpg', src: '24_CALCINHA_FIO_DUPLO_ESTAMPADO_04.jpg'},
    {name: '25_CALCINHA_FIO_DUPLO_LISA_02.jpg', src: '25_CALCINHA_FIO_DUPLO_LISA_02.jpg'},
    {name: '26_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_ESTAMPADO_02.jpg', src: '26_CALCINHA_TRADICIONAL_EMPINA_BUMBUM_ESTAMPADO_02.jpg'},
    {name: '27_BEACHWEAR______CALCINHA_DE_AMARRAR_ESTAMPADA_02.jpg', src: '27_BEACHWEAR______CALCINHA_DE_AMARRAR_ESTAMPADA_02.jpg'},
    {name: '28_CALCINHA_TRADICIONAL_ESTAMPADA_02.jpg', src: '28_CALCINHA_TRADICIONAL_ESTAMPADA_02.jpg'},
    {name: '29_CALCINHA_BICOLOR_ALÇA_REGULAVEL_02.jpg', src: '29_CALCINHA_BICOLOR_ALCA_REGULAVEL_02.jpg'},
    {name: '30_CALCINHA_FIO_DUPLO_ESTAMPADA_02.jpg', src: '30_CALCINHA_FIO_DUPLO_ESTAMPADA_02.jpg'},
    {name: '31_CALCINHA_FIO_DUPLO_LISA_02.jpg', src: '31_CALCINHA_FIO_DUPLO_LISA_02.jpg'},
    {name: '32_CALCINHA_COM_BABADO_LISA_DE_AMARRAR_FINA_EMPINA_BUMBUM_02.jpg', src: '32_CALCINHA_COM_BABADO_LISA_DE_AMARRAR_FINA_EMPINA_BUMBUM_02.jpg'},
    {name: '33_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg', src: '33_CALCINHA_LATERAL_DRAPEADA_LISA_02.jpg'}
];

const imagesGallery = [
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(DH)-_MG_5274 - bijuterias.jpg',
        src: '(DH)-_MG_5274_-_bijuterias.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(DH)-01-Capim-Dourado-bijuterias.jpg',
        src: '(DH)-01-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-19.jpg',
        src: '(DH)-Artesanato-19.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(DH)_MG_6129 - Jóias Contenporâneas.jpg',
        src: '(DH)_MG_6129_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_familia',
            /*'moda_feminina',*/
            /*'moda_masculina',*/
            'moda_infantil'
        ],
        originalName: '(DH)_MG_5584 - Cia Termal Beachewear - Moda Família - Moda Feminina - Moda Masculina - Moda Feminina Infantil - Moda Masculina Infantil.jpg',
        src: '(DH)_MG_5584_-_Cia_Termal_Beachewear_-_Moda_Familia_-_Moda_Feminina_-_Moda_Masculina_-_Moda_Feminina_Infantil_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(DH)-Prata-01.jpg',
        src: '(DH)-Prata-01.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(DH)_MG_4723 - Pró-Natura - Moda Feminina - Acessórios.jpg',
        src: '(DH)_MG_4723_-_Pro-Natura_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(DH)_MG_4704 - bijuterias.jpg',
        src: '(DH)_MG_4704_-_bijuterias.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(DH)-02-Capim-Dourado-bijuterias.jpg',
        src: '(DH)-02-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-20.jpg',
        src: '(DH)-Artesanato-20.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_masculina',
            'moda_infantil',
            'moda_familia',
            'acessorios'
        ],
        originalName: '(DH)_MG_5625 - Cia Termal Beachewear - Moda Masculina - Moda Masculina Infantil - Acessórios.jpg',
        src: '(DH)_MG_5625_-_Cia_Termal_Beachewear_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Acessorios.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(DH)_MG_6295 - Jóias Contenporâneas.jpg',
        src: '(DH)_MG_6295_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(DH)-Prata-02.jpg',
        src: '(DH)-Prata-02.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(DH)_MG_5518 - Pró-Natura - Moda Feminina.jpg',
        src: '(DH)_MG_5518_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(DH)-03-Capim-Dourado-bijuterias.jpg',
        src: '(DH)-03-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-21.jpg',
        src: '(DH)-Artesanato-21.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(DH)_MG_4985-bijuterias.jpg',
        src: '(DH)_MG_4985-bijuterias.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(DH)_MG_5679 - Cia Termal Beachewear - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(DH)_MG_5679_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(DH)_MG_6308 - Jóias Contenporâneas.jpg',
        src: '(DH)_MG_6308_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-03.jpg',
        src: '(H)-Prata-03.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(DH)_MG_5995 - Pró-Natura - Moda Feminina - Moda Bebê.jpg',
        src: '(DH)_MG_5995_-_Pro-Natura_-_Moda_Feminina_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-04-Capim-Dourado-bijuterias.jpg',
        src: '(H)-04-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-22.jpg',
        src: '(DH)-Artesanato-22.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(DH)_MG_5488 - bijuterias.jpg',
        src: '(DH)_MG_5488_-_bijuterias.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(DH)_MG_5980 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(DH)_MG_5980_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-04.jpg',
        src: '(H)-Prata-04.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(H)_MG_6212 - Jóias Contenporâneas.jpg',
        src: '(H)_MG_6212_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(H)_MG_5209 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(H)_MG_5209_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-05-Capim-Dourado-bijuterias.jpg',
        src: '(H)-05-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-23.jpg',
        src: '(DH)-Artesanato-23.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(DH)_MG_5949-bijuterias.jpg',
        src: '(DH)_MG_5949-bijuterias.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(H)_MG_5716 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(H)_MG_5716_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-05.jpg',
        src: '(H)-Prata-05.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(H)_MG_6233 - Jóias Contenporâneas.jpg',
        src: '(H)_MG_6233_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(H)_MG_5234 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(H)_MG_5234_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-06-Capim-Dourado-bijuterias.jpg',
        src: '(H)-06-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-24.jpg',
        src: '(DH)-Artesanato-24.jpg'
    },
    {
        categories: [
            'bijuterias',
            'acessorios'
        ],
        originalName: '(H)-02 - bijuterias - acessórios.jpg',
        src: '(H)-02_-_bijuterias_-_acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(H)_MG_5856 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(H)_MG_5856_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-06.jpg',
        src: '(H)-Prata-06.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(H)_MG_6324 - Jóias Contenporâneas.jpg',
        src: '(H)_MG_6324_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(H)_MG_5244 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(H)_MG_5244_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-07-Capim-Dourado-bijuterias.jpg',
        src: '(H)-07-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-25.jpg',
        src: '(DH)-Artesanato-25.jpg'
    },
    {
        categories: [
            'bijuterias',
            'acessorios'
        ],
        originalName: '(H)-14 - bijuterias - acessórios.jpg',
        src: '(H)-14_-_bijuterias_-_acessorios.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-07.jpg',
        src: '(H)-Prata-07.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(LH)_MG_6212 - Jóias Contenporâneas.jpg',
        src: '(LH)_MG_6212_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(H)_MG_5869 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(H)_MG_5869_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil'
        ],
        originalName: '(H)_MG_5294 - Pró-Natura - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(H)_MG_5294_-_Pro-Natura_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-08-Capim-Dourado-bijuterias.jpg',
        src: '(H)-08-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(DH)-Artesanato-26.jpg',
        src: '(DH)-Artesanato-26.jpg'
    },
    {
        categories: [
            'bijuterias',
            /*'bijuterias-acessorios'*/
        ],
        originalName: '(H)-16 - bijuterias-acessórios.jpg',
        src: '(H)-16_-_bijuterias-acessorios.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(LH)_MG_6233 - Jóias Contenporâneas.jpg',
        src: '(LH)_MG_6233_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-08.jpg',
        src: '(H)-Prata-08.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(H)_MG_5949 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(H)_MG_5949_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_infantil'
        ],
        originalName: '(H)_MG_6047 - Pró-Natura - Moda Bebê.jpg',
        src: '(H)_MG_6047_-_Pro-Natura_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-11-Capim-Dourado-bijuterias.jpg',
        src: '(H)-11-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-01.jpg',
        src: '(H)-Artesanato-01.jpg'
    },
    {
        categories: [
            'bijuterias',
            'acessorios'
        ],
        originalName: '(H)_MG_4627 - bijuterias - acessórios.jpg',
        src: '(H)_MG_4627_-_bijuterias_-_acessorios.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(LH)_MG_6324 - Jóias Contenporâneas.jpg',
        src: '(LH)_MG_6324_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(H)-Prata-09.jpg',
        src: '(H)-Prata-09.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(LH)_MG_5716 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(LH)_MG_5716_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(H)-12-Capim-Dourado-bijuterias.jpg',
        src: '(H)-12-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(LH)_MG_5209 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(LH)_MG_5209_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-02.jpg',
        src: '(H)-Artesanato-02.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(H)_MG_4704 - bijuterias.jpg',
        src: '(H)_MG_4704_-_bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6122 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6122_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(LH)_MG_5856 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(LH)_MG_5856_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-03.jpg',
        src: '(LH)-Prata-03.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(LH)_MG_5234 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(LH)_MG_5234_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-04-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-04-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-03.jpg',
        src: '(H)-Artesanato-03.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(H)_MG_5721-bijuterias.jpg',
        src: '(H)_MG_5721-bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6144 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6144_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-04.jpg',
        src: '(LH)-Prata-04.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(LH)_MG_5869 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(LH)_MG_5869_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(LH)_MG_5244 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(LH)_MG_5244_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-05-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-05-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-04.jpg',
        src: '(H)-Artesanato-04.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(H)_MG_5766 - bijuterias.jpg',
        src: '(H)_MG_5766_-_bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6182 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6182_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-05.jpg',
        src: '(LH)-Prata-05.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(LH)_MG_5949 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(LH)_MG_5949_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(LH)_MG_5294 - Pró-Natura - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(LH)_MG_5294_-_Pro-Natura_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-06-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-06-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-05.jpg',
        src: '(H)-Artesanato-05.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(H)_MG_5770-bijuterias-acessorios.jpg',
        src: '(H)_MG_5770-bijuterias-acessorios.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6190 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6190_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-06.jpg',
        src: '(LH)-Prata-06.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear'
        ],
        originalName: '(V)-Cia-Termal-Beachewear-Moda-Feminina.jpg',
        src: '(V)-Cia-Termal-Beachewear-Moda-Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_masculina',
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(LH)_MG_5346 - Pró-Natura - Moda Masculina - Moda Masculina Infantil - Moda Família.jpg',
        src: '(LH)_MG_5346_-_Pro-Natura_-_Moda_Masculina_-_Moda_Masculina_Infantil_-_Moda_Familia.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-07-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-07-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-06.jpg',
        src: '(H)-Artesanato-06.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(H)_MG_5931 - bijuterias.jpg',
        src: '(H)_MG_5931_-_bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6199 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6199_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-07.jpg',
        src: '(LH)-Prata-07.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_infantil'
        ],
        originalName: '(LH)_MG_6047 - Pró-Natura - Moda Bebê.jpg',
        src: '(LH)_MG_6047_-_Pro-Natura_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_familia'
        ],
        originalName: '(V)-Moda-Feminina-Acessórios.jpg',
        src: '(V)-Moda-Feminina-Acessorios.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-08-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-08-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-07.jpg',
        src: '(H)-Artesanato-07.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(LH)_MG_5721-bijuterias.jpg',
        src: '(LH)_MG_5721-bijuterias.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-08.jpg',
        src: '(LH)-Prata-08.jpg'
    },
    /*{
        categories: [
            'semijoias',
            /!*'joias_contemporaneas'*!/
        ],
        originalName: '(V)_MG_6242 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6242_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura'
        ],
        originalName: '(V)-11-acessórios.jpg',
        src: '(V)-11-acessorios.jpg'
    },*/
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)-SLS_5415 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(V)-SLS_5415_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-11-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-11-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(LH)-Prata-09.jpg',
        src: '(LH)-Prata-09.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-08.jpg',
        src: '(H)-Artesanato-08.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(LH)_MG_5766 - bijuterias.jpg',
        src: '(LH)_MG_5766_-_bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6256 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6256_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_4627 - Pró-Natura - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_4627_-_Pro-Natura_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_4760 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_4760_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(LH)-12-Capim-Dourado-bijuterias.jpg',
        src: '(LH)-12-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-09.jpg',
        src: '(H)-Artesanato-09.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-01.jpg',
        src: '(V)-Prata-01.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(LH)_MG_5770-bijuterias-acessorios.jpg',
        src: '(LH)_MG_5770-bijuterias-acessorios.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6260 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6260_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_4786 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_4786_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'cia_termal',*/
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5274 - Cia Termal - Moda Feminina.jpg',
        src: '(V)_MG_5274_-_Cia_Termal_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)-09-Capim-Dourado-bijuterias.jpg',
        src: '(V)-09-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-10.jpg',
        src: '(H)-Artesanato-10.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-02.jpg',
        src: '(V)-Prata-02.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(LH)_MG_5931 - bijuterias.jpg',
        src: '(LH)_MG_5931_-_bijuterias.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6273 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6273_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)-10-Capim-Dourado-bijuterias.jpg',
        src: '(V)-10-Capim-Dourado-bijuterias.jpg'
    },
    {
        categories: [
            'pro_natura'
        ],
        originalName: '(V)_MG_4877-acessórios.jpg',
        src: '(V)_MG_4877-acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_5569 - Cia Termal Beachewear - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(V)_MG_5569_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-11.jpg',
        src: '(H)-Artesanato-11.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-03.jpg',
        src: '(V)-Prata-03.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6286 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6286_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(V)_MG_4835 - bijuterias.jpg',
        src: '(V)_MG_4835_-_bijuterias.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)_MG_6077 - capim dourado - bijuterias.jpg',
        src: '(V)_MG_6077_-_capim_dourado_-_bijuterias.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_4901 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_4901_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_masculina',
            'moda_infantil'
        ],
        originalName: '(V)_MG_5610 - Cia Termal Beachewear - Moda Masculina - Moda Masculina Infantil.jpg',
        src: '(V)_MG_5610_-_Cia_Termal_Beachewear_-_Moda_Masculina_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-12.jpg',
        src: '(H)-Artesanato-12.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6310 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6310_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-04.jpg',
        src: '(V)-Prata-04.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(V)_MG_4897 - bijuterias.jpg',
        src: '(V)_MG_4897_-_bijuterias.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)_MG_6092 - capim dourado - bijuterias.jpg',
        src: '(V)_MG_6092_-_capim_dourado_-_bijuterias.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_4919 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_4919_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-13.jpg',
        src: '(H)-Artesanato-13.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_masculina'
        ],
        originalName: '(V)_MG_5653 - Cia Termal Beachewear - Moda Masculina.jpg',
        src: '(V)_MG_5653_-_Cia_Termal_Beachewear_-_Moda_Masculina.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-05.jpg',
        src: '(V)-Prata-05.jpg'
    },
    {
        categories: [
            'semijoias',
            /*'joias_contemporaneas'*/
        ],
        originalName: '(V)_MG_6327 - Jóias Contenporâneas.jpg',
        src: '(V)_MG_6327_-_Joias_Contenporaneas.jpg'
    },
    {
        categories: [
            'bijuterias'
        ],
        originalName: '(V)_MG_4919 - bijuterias.jpg',
        src: '(V)_MG_4919_-_bijuterias.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)_MG_6102 - capim dourado - bijuterias.jpg',
        src: '(V)_MG_6102_-_capim_dourado_-_bijuterias.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_4943 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_4943_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_5663 - Cia Termal Beachewear - Moda Masculina Infantil.jpg',
        src: '(V)_MG_5663_-_Cia_Termal_Beachewear_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-14.jpg',
        src: '(H)-Artesanato-14.jpg'
    },
    {
        categories: [
            'prata'
        ],
        originalName: '(V)-Prata-06.jpg',
        src: '(V)-Prata-06.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)_MG_6113 - capim dourado - bijuterias.jpg',
        src: '(V)_MG_6113_-_capim_dourado_-_bijuterias.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_5700 - Cia Termal Beachewear - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(V)_MG_5700_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-15.jpg',
        src: '(H)-Artesanato-15.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_4985 - Pró-Natura - Moda Feminina - acessórios.jpg',
        src: '(V)_MG_4985_-_Pro-Natura_-_Moda_Feminina_-_acessorios.jpg'
    },
    {
        categories: [
            'capim_dourado'
        ],
        originalName: '(V)_MG_6120 - capim dourado - bijuterias.jpg',
        src: '(V)_MG_6120_-_capim_dourado_-_bijuterias.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_5702 - Cia Termal Beachewear - Moda Masculina Infantil.jpg',
        src: '(V)_MG_5702_-_Cia_Termal_Beachewear_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-16.jpg',
        src: '(H)-Artesanato-16.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5036 - Pró-Natura - Moda Feminina - acessórios.jpg',
        src: '(V)_MG_5036_-_Pro-Natura_-_Moda_Feminina_-_acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5721 - Cia Termal Beachewear - Moda Feminina - acess[orios.jpg',
        src: '(V)_MG_5721_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_acess[orios.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-17.jpg',
        src: '(H)-Artesanato-17.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5041 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_5041_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5752 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_5752_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-18.jpg',
        src: '(H)-Artesanato-18.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_5069 - Pró-Natura - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(V)_MG_5069_-_Pro-Natura_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5765 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5765_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-27.jpg',
        src: '(H)-Artesanato-27.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5120 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_5120_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5770 - Cia Termal Beachewear - Moda Feminina - Acessorios.jpg',
        src: '(V)_MG_5770_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(H)-Artesanato-28.jpg',
        src: '(H)-Artesanato-28.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_5146 - Pró-Natura - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(V)_MG_5146_-_Pro-Natura_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5793 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_5793_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-02.jpg',
        src: '(LH)-Artesanato-02.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_5221 - Pró-Natura - Moda Feminina - Moda Feminina Infantil.jpg',
        src: '(V)_MG_5221_-_Pro-Natura_-_Moda_Feminina_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5794 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5794_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-03.jpg',
        src: '(LH)-Artesanato-03.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5368 - Pró-Natura - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_5368_-_Pro-Natura_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5804 - Cia Termal Beachewear - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_5804_-_Cia_Termal_Beachewear_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-04.jpg',
        src: '(LH)-Artesanato-04.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'acessorios'
        ],
        originalName: '(V)_MG_5387 - Pró-Natura - Moda Feminina - Acessórios.jpg',
        src: '(V)_MG_5387_-_Pro-Natura_-_Moda_Feminina_-_Acessorios.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5842 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5842_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-05.jpg',
        src: '(LH)-Artesanato-05.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5413 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_5413_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5886 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5886_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-06.jpg',
        src: '(LH)-Artesanato-06.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5426 - Pró-Natura - Moda Feminina.jpg',
        src: '(V)_MG_5426_-_Pro-Natura_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5905 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5905_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-07.jpg',
        src: '(LH)-Artesanato-07.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_infantil'
        ],
        originalName: '(V)_MG_5441 - Pró-Natura - Moda Masculina Infantil.jpg',
        src: '(V)_MG_5441_-_Pro-Natura_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5931 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5931_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-08.jpg',
        src: '(LH)-Artesanato-08.jpg'
    },
    {
        categories: [
            'pro_natura',
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5458 - Pró-Natura - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5458_-_Pro-Natura_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5941 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5941_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-09.jpg',
        src: '(LH)-Artesanato-09.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            /*'moda_feminina'*/
        ],
        originalName: '(V)_MG_5954 - Cia Termal Beachewear - Moda Feminina.jpg',
        src: '(V)_MG_5954_-_Cia_Termal_Beachewear_-_Moda_Feminina.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_infantil'
        ],
        originalName: '(V)_MG_5463 - Pró-Natura - Moda Masculina Infantil.jpg',
        src: '(V)_MG_5463_-_Pro-Natura_-_Moda_Masculina_Infantil.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-10.jpg',
        src: '(LH)-Artesanato-10.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6028 - Cia Termal Beachewear - Moda Feminina Infantil.jpg',
        src: '(V)_MG_6028_-_Cia_Termal_Beachewear_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'pro_natura',
            /*'moda_feminina',*/
            'moda_infantil',
            'moda_familia'
        ],
        originalName: '(V)_MG_6011 - Pró-Natura - Moda Feminina - Moda Bebê.jpg',
        src: '(V)_MG_6011_-_Pro-Natura_-_Moda_Feminina_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-11.jpg',
        src: '(LH)-Artesanato-11.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6040 - Cia Termal Beachewear - Moda Feminina Infantil.jpg',
        src: '(V)_MG_6040_-_Cia_Termal_Beachewear_-_Moda_Feminina_Infantil.jpg'
    },
    {
        categories: [
            'pro_natura',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6034 - Pró-Natura - Moda Bebê.jpg',
        src: '(V)_MG_6034_-_Pro-Natura_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-12.jpg',
        src: '(LH)-Artesanato-12.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6055 - Cia Termal Beachewear - Moda Bebê.jpg',
        src: '(V)_MG_6055_-_Cia_Termal_Beachewear_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-13.jpg',
        src: '(LH)-Artesanato-13.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6060 - Cia Termal Beachewear - Moda Bebê.jpg',
        src: '(V)_MG_6060_-_Cia_Termal_Beachewear_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-14.jpg',
        src: '(LH)-Artesanato-14.jpg'
    },
    {
        categories: [
            'cia_termal_beachewear',
            'moda_infantil'
        ],
        originalName: '(V)_MG_6066 - Cia Termal Beachewear - Moda Bebê.jpg',
        src: '(V)_MG_6066_-_Cia_Termal_Beachewear_-_Moda_Bebe.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-15.jpg',
        src: '(LH)-Artesanato-15.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-16.jpg',
        src: '(LH)-Artesanato-16.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-17.jpg',
        src: '(LH)-Artesanato-17.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-18.jpg',
        src: '(LH)-Artesanato-18.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-27.jpg',
        src: '(LH)-Artesanato-27.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(LH)-Artesanato-28.jpg',
        src: '(LH)-Artesanato-28.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-01.jpg',
        src: '(V)-Artesanato-01.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-02.jpg',
        src: '(V)-Artesanato-02.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-03.jpg',
        src: '(V)-Artesanato-03.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-04.jpg',
        src: '(V)-Artesanato-04.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-05.jpg',
        src: '(V)-Artesanato-05.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-06.jpg',
        src: '(V)-Artesanato-06.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-07.jpg',
        src: '(V)-Artesanato-07.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-08.jpg',
        src: '(V)-Artesanato-08.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-09.jpg',
        src: '(V)-Artesanato-09.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-10.jpg',
        src: '(V)-Artesanato-10.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-11.jpg',
        src: '(V)-Artesanato-11.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-12.jpg',
        src: '(V)-Artesanato-12.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-13.jpg',
        src: '(V)-Artesanato-13.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-14.jpg',
        src: '(V)-Artesanato-14.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-15.jpg',
        src: '(V)-Artesanato-15.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-16.jpg',
        src: '(V)-Artesanato-16.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-17.jpg',
        src: '(V)-Artesanato-17.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-18.jpg',
        src: '(V)-Artesanato-18.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-19.jpg',
        src: '(V)-Artesanato-19.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-20.jpg',
        src: '(V)-Artesanato-20.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-21.jpg',
        src: '(V)-Artesanato-21.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-22.jpg',
        src: '(V)-Artesanato-22.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-23.jpg',
        src: '(V)-Artesanato-23.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-24.jpg',
        src: '(V)-Artesanato-24.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-25.jpg',
        src: '(V)-Artesanato-25.jpg'
    },
    {
        categories: [
            'artesanato'
        ],
        originalName: '(V)-Artesanato-26.jpg',
        src: '(V)-Artesanato-26.jpg'
    }
];

export {imagesTop, imagesDown, imagesGallery};
