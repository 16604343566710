import React from 'react';
import './style/native/App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './pages/home';
import MakeYourself from "./pages/makeYourself";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/monte-seu-biquini' component={MakeYourself} exact />
                <Route component={() => <div>404</div>} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
