import React, {Component} from "react";
import {mapSeries} from 'p-iteration';
import {uniq} from 'underscore';
import {clone} from 'ramda';
import Masonry from 'react-masonry-css'

import {imagesGallery} from './images';
import Loader from "./loader";

export default class GallerySection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classFiltered: '',
            limit: 10,
            countImage: 0,
            categories: [],
            image: []
        }

        this.loadImages();
    }

    async loadImages() {
        const imagesContext = require.context('../style/images/cia/gallery/', true);

        this.setState({
            image: await mapSeries(imagesGallery, async (img) => {
                return {
                    classCategory: `${img.categories.map(value => `${value}`).join(' ')}`,
                    img: imagesContext(`./${img.src}`),
                    originalSrc: img.src,
                    description: img.originalName.split('_').join(' ').split('-').join(' ').split('.jpg')[0]
                }
            })
        });

        this.setState({
            categories: uniq(clone(this.state.image).map(value => value.classCategory).join(' ').split(' '))
        });

        this.setState({
            image: this.state.image.sort(() => Math.random() - 0.5)
        });
    }

    checkImagesLoaded() {
        this.setState({countImage : this.state.countImage + 1});
    }

    changeLimitUp() {
        this.setState({limit: this.state.limit + 10});
    }

    changeLimitDown() {
        this.setState({limit: this.state.limit - 10});
    }

    setClassFiltered(className) {
        this.setState({limit: 10});
        this.setState({classFiltered: className})
    }

    render() {
        return <div>

            {this.state.countImage < 9 ?
                (<Loader show={true} />) :
                (<Loader show={false} />)
            }

            <section id="work" className="portfolio-two">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-title mb-2rem wow fadeIn" data-wow-delay="300ms">
                                <h5>Conheça</h5>
                                <h2>Shopping CIA Termal</h2>
                                <p>Somos uma empresa focada em atender e surpreender os visitantes do maior parque aquático de águas termais do mundo. Atenta as inovações e tendências da moda e do mercado, conciliando qualidade, conforto e variedades, em um ambiente amplo e agradável, onde cada cliente é atendido de forma personalizada.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 d-block">
                        <div className="filtering col-sm-12 text-center pt-2 mb-40px" style={{textTransform: 'capitalize'}}>
                            <span className="active" onClick={() => this.setClassFiltered('*')}>Todas</span>
                            {this.state.categories.map((item, key) => (<span key={key} onClick={() => this.setClassFiltered(item)}>{
                                item
                                    .split('_')
                                    .join(' ')
                                    .split('-')
                                    .join(' ')
                            }</span>))}
                        </div>

                        <Masonry
                            breakpointCols={{
                                default: 4,
                                1100: 3,
                                700: 2,
                                500: 1
                            }}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column">
                            {this.state.image
                                .filter(value => this.state.classFiltered === '*' ? true : value.classCategory ? value.classCategory.includes(this.state.classFiltered) : true)
                                .filter(value => !value.originalSrc.includes('(LH)'))
                                .slice(0, this.state.limit).map((image, key) => (
                                    <div key={key} className={`items ${image.classCategory} ${image.originalSrc.includes('(DH)') ? 'col-md-12' : image.originalSrc.includes('(H)') ? 'col-md-12' : 'col-md-12'}`}>
                                        <div className="item-img">
                                            <a href={image.originalSrc.includes('(H)') ?
                                                this.state.image.find(value => value.originalSrc === image.originalSrc.replace('(H)', '(LH)')) ?
                                                    this.state.image.find(value => value.originalSrc === image.originalSrc.replace('(H)', '(LH)')).img :
                                                    image.img :
                                                image.img}
                                               data-fancybox="images">

                                                <img onLoad={this.checkImagesLoaded.bind(this)}
                                                     onError={this.checkImagesLoaded.bind(this)}
                                                     src={image.img} alt={image.img} />

                                                <div className="item-img-overlay valign">
                                                    <div className="overlay-info">
                                                        {/*<span className="plus mb-3" />*/}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }
                        </Masonry>
                    </div>

                    <div className="row" style={{marginBottom: '-55px', marginTop: '30px', justifyContent: 'center'}}>
                        {this.state.image
                            .filter(value => !value.originalSrc.includes('(LH)'))
                            .filter(value => this.state.classFiltered === '*' ? true : value.classCategory ? value.classCategory.includes(this.state.classFiltered) : true)
                            .length > this.state.limit ?
                            (
                                <button className="btn-setting radius-0 btn-yellow text-uppercase"
                                        style={{marginRight: '20px'}}
                                        onClick={this.changeLimitUp.bind(this)}>Carregar mais 10 fotos</button>
                            ) :
                            ''
                        }

                        {this.state.limit >= 20 ?
                            (<button className="btn-setting radius-0 btn-yellow text-uppercase"
                                     style={{marginRight: '20px'}}
                                     onClick={this.changeLimitDown.bind(this)}>Esconder 10 fotos</button>) :
                            ''
                        }

                        <a href='/#work' className="btn-setting radius-0 btn-yellow text-uppercase">Voltar para o filtro</a>
                    </div>
                </div>
            </section>
        </div>
    }
}
