import React, {Component} from 'react';
import {mapSeries} from 'p-iteration';

import {imagesTop, imagesDown} from "./images";
import Loader from "./loader";

export default class MakeYourselfChooseSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countImage: 0,
            top: [],
            down: []
        }

        this.loadImages();
    }

    async loadImages() {
        const imagesContextTop = require.context('../style/images/cia/monte-seu-biquini/top', true);
        const imagesContextDown = require.context('../style/images/cia/monte-seu-biquini/down', true);

        this.setState({
            top: await mapSeries(imagesTop, async (img) => {
                return {
                    img: imagesContextTop(`./${img.src}`),
                    description: img.name.split('_').join(' ').split('.jpg')[0]
                }
            }),
            down: await mapSeries(imagesDown, async (img) => {
                return  {
                    img: imagesContextDown(`./${img.src}`),
                    description: img.name.split('_').join(' ').split('.jpg')[0]
                }
            })
        })
    }

    checkImagesLoaded() {
        this.setState({countImage : this.state.countImage + 1});
    }

    render() {
        return <div>
            {this.state.countImage < 100 ?
                (<Loader show={true} />) :
                (<Loader show={false} />)
            }

            <section id="team" className="bg-light-gray" style={{marginTop: '100px'}}>
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-title wow fadeIn"> {/*data-wow-delay="1000ms"*/}
                                <h5>Porque você é única</h5>
                                <h2>Monte Seu Biquini</h2>

                                <p>Sermos diferentes, sermos únicas, É o que nos faz especiais. Não precisamos gostar Das mesmas coisas, das mesmas cores. Nem usar os mesmos tamanhos para nós sentirmos lindas. Porque podemos escolhero O que achamos que melhor combina com o nosso corpo. O que melhor combina Com a nossa alma.</p>
                            </div>
                        </div>
                    </div>

                    {/*BIQUINI 1*/}
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="swiper-container team-member-slider">
                                <div className="swiper-wrapper">
                                    {this.state.top.map((img, key) => (
                                        <div className="swiper-slide" key={key}>
                                            <div className="team-coverflow">
                                                <div className="team-data-img wow fadeIn">
                                                    <div className="team-img">
                                                        <img src={img.img}
                                                             onLoad={this.checkImagesLoaded.bind(this)}
                                                             onError={this.checkImagesLoaded.bind(this)}
                                                             alt="team"
                                                             className="img-responsive"
                                                             data-no-retina />

                                                        <div className="team-box-content bg-yellow">
                                                            {img.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="swiper-button-next slider-arrows">
                                    <i className="ti ti-angle-right"/>
                                </div>
                                <div className="swiper-button-prev slider-arrows">
                                    <i className="ti ti-angle-left"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*BIQUINI 2*/}
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="swiper-container team-member-slider">
                                <div className="swiper-wrapper">

                                    {this.state.down.map((img, key) => (
                                        <div className="swiper-slide" key={key}>
                                            <div className="team-coverflow">
                                                <div className="team-data-img wow fadeIn">
                                                    <div className="team-img">
                                                        <img src={img.img}
                                                             onLoad={this.checkImagesLoaded.bind(this)}
                                                             onError={this.checkImagesLoaded.bind(this)}
                                                             alt="team"
                                                             className="img-responsive"
                                                             data-no-retina />

                                                        <div className="team-box-content bg-yellow">
                                                            {img.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="swiper-button-next slider-arrows">
                                    <i className="ti ti-angle-right"/>
                                </div>
                                <div className="swiper-button-prev slider-arrows">
                                    <i className="ti ti-angle-left"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    }
}
