import React, {Component} from "react";
import clientOne from '../style/images/cia/parceiros/acqua.jpg';
import clientTwo from '../style/images/cia/parceiros/ciamaritima.png';
import clientThree from '../style/images/cia/parceiros/manvar_logo.png';

export default class PartnersSection extends Component {
    render() {
        return <section id="partners" className="pb-0">
            <div className="container">

                <div className="brand-carousel owl-carousel owl-theme">
                    <div className="item">
                        <img src={clientOne} alt="Logo" />
                    </div>

                    <div className="item">
                        <img src={clientTwo} alt="Logo" />
                    </div>

                    <div className="item">
                        <img src={clientThree} alt="Logo" />
                    </div>
                </div>

            </div>
        </section>
    }
}
