import React, {Component} from 'react';
import banner from '../style/images/cia/banner_CIA_TERMAL_site_dourado_2.jpg';

export default class MainSlider extends Component {
    render() {
        return <div>
            <section id="home" className="p-0 no-transition">
                <img id="banner-main"
                     style={{marginTop: '114px'}}
                     src={banner}
                     alt=""
                     data-bgposition="center center"
                     data-bgfit="contain"
                     data-bgrepeat="no-repeat"
                     className="rev-slidebg"
                     data-bgparallax="10"
                     data-no-retina/>
            </section>
        </div>
    }
}
