import React, {Component} from "react";

export default class TextBoxSection extends Component {
    render() {
        return <div>
            <section id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-title wow fadeIn" data-wow-delay="300ms">
                                <h5>SHOPPING CIA TERMAL</h5>
                                <h2>TEM O QUE VOCÊ PROCURA E O QUE NÃO IMAGINA</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}
