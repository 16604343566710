import React, {Component} from "react";

export default class Footer extends Component {
    constructor() {
        super();

        this.state = {
            date : new Date()
        }
    }

    render() {
        return <div>
            <footer className="p-half bg-black2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-center">
                            <ul className="footer-icons mb-4">
                                <li>
                                    <a href="https://www.facebook.com/ciatermal" target="_blank" rel="noopener noreferrer" className="wow fadeInUp">
                                        <i className="ti ti-facebook"/>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.instagram.com/cia.termal/" target="_blank" rel="noopener noreferrer" className="wow fadeInUp">
                                        <i className="ti ti-instagram"/>
                                    </a>
                                </li>
                            </ul>

                            <p className="copyrights mt-2 mb-2">
                                © {this.state.date.getFullYear().toString()} Cia Termal. Made with love by <a href="https://weggo.ca/" rel="noopener noreferrer" target="_blank">Weggo</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    }
}
